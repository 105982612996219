// 任务指标管理
import request from '@/utils/request';
import { BASE_URL } from '../config.js';
let ModuleName = 'frontIndex';
export default {
    add(data) {
        // 新增
        return request.post(`${BASE_URL}/${ModuleName}/add`, data);
    },
    delete(id) {
        //删除
        return request.get(`${BASE_URL}/${ModuleName}/delete`, { params: { id: id } });
    },
    update(data) {
        // 修改
        return request.post(`${BASE_URL}/${ModuleName}/update`, data);
    },
    getById(id) {
        // 根据id查询指标
        return request.get(`${BASE_URL}/${ModuleName}/getById`, { params: { id: id } });
    },
    getReviewedById(id) {
        // 根据id查询指标
        return request.get(`${BASE_URL}/${ModuleName}/getReviewedById`, { params: { id: id } });
    },
    getIndexesByActionId(id) {
        // 根据任务id查询指标
        return request.get(`${BASE_URL}/${ModuleName}/getIndexesByActionId`, { params: { actionId: id } });
    },
    reviewed(query) {
        //分页查询
        return request.get(`${BASE_URL}/${ModuleName}/reviewed`, { params: query });
    },
    getPage(query) {
        //分页查询
        return request.get(`${BASE_URL}/${ModuleName}/getPage`, { params: query });
    },
    getIndexNumberByActionId(id) {
        // 获取任务下指标数量
        return request.get(`${BASE_URL}/${ModuleName}/getIndexNumberByActionId`, { params: { actionId: id } });
    },
    getIndexNumberVerifyBackByActionId(id) {
        // 获取任务下审核驳回的指标数量
        return request.get(`${BASE_URL}/${ModuleName}/getIndexNumberVerifyBackByActionId`, {
            params: { actionId: id },
        });
    },
    updateToVerifying(ids) {
        // 提交审核
        return request.get(`${BASE_URL}/${ModuleName}/updateToVerifying`, { params: { indexIds: ids } });
    },
    updateList(data) {
        // 保存审核
        return request.post(`${BASE_URL}/${ModuleName}/updateList`, data);
    },
    getIndexesNotDraftByActionId(id) {
        // 查看任务下有没有除了草稿状态的指标
        return request.get(`${BASE_URL}/${ModuleName}/getIndexesNotDraftByActionId`, { params: { actionId: id } });
    },
    getIndexesNumberNotDraftAndVerifyBackByActionId(id) {
        // 修改重大任务的判断
        return request.get(`${BASE_URL}/${ModuleName}/getIndexesNumberNotDraftAndVerifyBackByActionId`, {
            params: { actionId: id },
        });
    },
    backToDraft(id) {
        // 任务下所有指标退回到草稿状态
        return request.get(`${BASE_URL}/${ModuleName}/backToDraft`, { params: { actionId: id } });
    },
};
