// 任务管理接口
import request from '@/utils/request';
import { BASE_URL } from '../config.js';
let ModuleName = 'frontAction'; //任务
export default {
    add(data) {
        // 新增任务
        return request.post(`${BASE_URL}/${ModuleName}/add`, data);
    },
    delete(id) {
        // 删除任务
        return request.get(`${BASE_URL}/${ModuleName}/delete`, { params: { id: id } });
    },
    update(data) {
        // 修改任务
        return request.post(`${BASE_URL}/${ModuleName}/update`, data);
    },
    getById(id) {
        // 根据id查询任务
        return request.get(`${BASE_URL}/${ModuleName}/getById`, { params: { id: id } });
    },
    getReviewedZbById(id) {
        // 根据id查询任务
        return request.get(`${BASE_URL}/${ModuleName}/getReviewedZbById`, { params: { id: id } });
    },
    getReviewedById(id) {
        // 根据id查询任务
        return request.get(`${BASE_URL}/${ModuleName}/getReviewedById`, { params: { id: id } });
    },
    toBeReviewed(query) {
        // 待审核分页查询任务
        return request.get(`${BASE_URL}/${ModuleName}/toBeReviewed`, { params: query });
    },
    getPage(query) {
        // 待审核分页查询任务
        return request.get(`${BASE_URL}/${ModuleName}/getPage`, { params: query });
    },
    reviewed(query) {
        // 已审核重大任务分页查询任务
        return request.get(`${BASE_URL}/${ModuleName}/reviewed`, { params: query });
    },
    actionMove(query) {
        // 0为上移，1为下移
        return request.get(`${BASE_URL}/${ModuleName}/actionMove`, { params: query });
    },
    addAndUpdateToVerifying(data) {
        // 新增重大任务 保存并提交审核
        return request.post(`${BASE_URL}/${ModuleName}/addAndUpdateToVerifying`, data);
    },
    update(data) {
        // 新增重大任务 保存并提交审核
        return request.post(`${BASE_URL}/${ModuleName}/update`, data);
    },
};
