import { ElMessage } from 'element-plus'
function resMsg(res, successFn, errorFn) {
	if (res.code === 200) {
		ElMessage.success({
			message: '成功',
		})
		successFn && successFn()
	} else {
		ElMessage.error({
			message: res.msg ? res.msg : '失败',
		})
		errorFn && errorFn()
	}
}

export default resMsg
