import { createApp } from 'vue';
import App from './App.vue';

import router from './router';
import store from './store';

import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import api from '@/api';
import auth from '@/directives/auth';
import unAuth from '@/directives/unAuth';

import 'element-plus/dist/index.css';
import dd from 'gdt-jsapi';
import resMsg from '@/utils/resMsg.js';

dd.ready(() => {
    dd.getAuthCode({ corpId: '50178451' })
        .then((result) => {
            console.log(result);
        })
        .catch((err) => {
            console.log(err);
        });
});
window.dashboardUrl =
    process.env.NODE_ENV === 'production'
        ? '//' + location.host + '/dashboard'
        : '//' + location.hostname + ':9080/dashboard';
window.redirectUrl =
    process.env.VUE_APP_API_ENV_IRONMENT === 'production' ? 'http://59.202.42.63' : 'http://101.201.223.108';

const app = createApp(App);
app.config.globalProperties.$api = api;
app.config.globalProperties.$resMsg = resMsg; //请求结果

app.directive('auth', auth);
app.directive('unAuth', unAuth);

import { ElMessage } from 'element-plus';
import { GetQueryString } from '@/utils/common';

axios.interceptors.request.use(
    (config) => {
        config.url = process.env.VUE_APP_API_BASE_URL + config.url;
        let token = localStorage.getItem('token');
        if (token) {
            config.headers.common['Authorization'] = token;
        }
        return config;
    },
    (err) => {
        console.error(err);
        return Promise.reject(err);
    }
);
axios.interceptors.response.use(
    (res) => {
        console.log('response', res);
        if (res.data.code === 200) {
            return res.data.data || res.data.rows || res.data; //.data || res.data.rows
        } else if (res.data.token) {
            return res;
        } else {
            switch (res.data.code) {
                case 401:
                    // 提醒用户登录失效,保证只有一次弹窗
                    localStorage.setItem('isLoginOut', true);
                    localStorage.removeItem('token');
                    sessionStorage.clear();
                    getToken();
                    break;
                case 402:
                    // uacCode 过期
                    localStorage.removeItem('uacCode');
                    break;
                case 440:
                    ElMessage.error(res.data.msg);
                    return res.data;
                    break;
                case 500:
                    ElMessage.error(res.data.msg);
                    return res.data;
                    break;
                default:
            }
            if (res.data) {
                return res.data;
            } else {
                return res;
            }
        }
    },
    (err) => {
        console.error(err);
        return Promise.reject(err);
    }
);
function getToken() {
    let code = localStorage.code || '';
    let baseURL = process.env.NODE_ENV === 'development' ? store.state.user.baseURLToken : '';
    let redirectUrl = process.env.VUE_APP_API_LOGINOUT_URL;
    let idToken = new URLSearchParams(location.search).get('id_token');
    let redirect = location.search.split('?')[1];
    let type = location.search.split('?')[2];
    axios({
        method: 'post',
        url: `/front/ssoLogin/`,
        data: { idToken, type, redirect },
        baseURL: baseURL,
    })
        .then((res) => {
            console.log(res);
            if (res) {
                res.token && localStorage.setItem('token', res.token);
            }
            if (res.code == 500) {
                localStorage.removeItem('token');
                localStorage.removeItem('code');
                window.location.replace(redirectUrl);
            }
        })
        .catch((err) => {
            localStorage.removeItem('token');
            localStorage.removeItem('code');
            window.location.replace(redirectUrl);
        });
}
function init() {
    let redirectUrl = process.env.VUE_APP_API_IDAAS_URL;
    let idToken = new URLSearchParams(location.search).get('id_token');
    let redirect = location.search.split('?')[1];
    let type = location.search.split('?')[2];
    if (idToken) {
        localStorage.setItem('code', idToken);
        let baseURL = process.env.NODE_ENV === 'development' ? store.state.user.baseURLToken : '';
        axios({
            method: 'post',
            url: '/front/ssoLogin/',
            data: {
                idToken,
                type,
                redirect,
            },
            baseURL: baseURL,
        }).then((res) => {
            console.log('获取token', res);
            if (res.user) {
                localStorage.setItem('token', res.token);
                axios.defaults.headers.common['Authorization'] = res.token;
                store.commit('user/set_userinfo', res.user);
                store.dispatch('user/userinfo');
                // 如果有redirect_url代表跳转到驾驶舱
                if (res.redirect_url) {
                    location.replace(res.redirect_url[0].redirectUrl);
                    return;
                }
                handleInit();
            } else {
                localStorage.removeItem('token');
                localStorage.removeItem('code');
                window.location.replace(redirectUrl);
            }
        });
    } else {
        location.replace(redirectUrl);
    }
}

init();
// handleLogin();
// function handleLogin() {
//
//     let timer = setInterval(() => {
//         if (store.state.user.userinfo.id) {
//             handleInit();
//             clearInterval(timer);
//         }
//     }, 500);
// }
function handleInit() {
    app.use(ElementPlus, {
        locale: zhCn,
    })
        .use(router)
        .use(store)
        .mount('#app');
}
import * as icon from '@element-plus/icons-vue';
Object.values(icon).forEach((e) => {
    app.component(e.name, e);
});
// 屏蔽警告信息
// app.config.warnHandler = () => null;
// app._context.components.ElTable.TableColumn.props.showOverflowTooltip = { type: Boolean, default: true }; //table不换行，toolip展示
// app._context.components.ElTable.props.stripe = { type: Boolean, default: true }; //table斑马纹
// app._context.components.ElTable.props.cellStyle = { type: Object, default: { height: '54px' } }; //table高度
