import { createRouter, createWebHashHistory } from 'vue-router';
import Home from '../views/Home.vue';
import store from '@/store';
import { regMobile } from '@/utils/common';
import dd from 'gdt-jsapi';
import { GetQueryString } from '@/utils/common';

const routes = [
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        redirect: `/messageCenter`,
        children: [
            {
                path: '/reportOnTheMission',
                name: 'reportOnTheMission', //首页
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                    tabName: '任务上报',
                },
                component: () => import(`../views/taskManagement/taskList.vue`),
            },
            {
                path: '/addOrEditTask',
                name: 'addOrEditTask', //新增指标
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                    tabName: '任务上报',
                },
                component: () => import(`../views/taskManagement/addOrEditTask.vue`),
            },
            {
                path: '/taskReview',
                name: 'taskReview', //任务审核
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                    tabName: '任务审核',
                },
                component: () => import(`../views/TaskReview/taskReview.vue`),
            },
            {
                path: '/tastReviewDialog',
                name: 'tastReviewDialog', //审核
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                    tabName: '任务审核',
                },
                component: () => import(`../views/TaskReview/tastReviewDialog.vue`),
            },
            {
                path: '/taskExecution',
                name: 'taskExecution', //任务执行
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                    tabName: '任务执行',
                },
                component: () => import(`../views/taskExecution/taskExecution.vue`),
            },
            {
                path: '/fillTask',
                name: 'fillTask', //任务填报
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                    tabName: '任务执行',
                },
                component: () => import(`../views/taskExecution/fillTask.vue`),
            },
            {
                path: '/messageCenter',
                name: 'messageCenter', //消息中心
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                    tabName: '消息中心',
                },
                component: () => import(`../views/messageCenter/messageCenter.vue`),
            },
            {
                path: '/userManagement',
                name: 'userManagement', //角色管理
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                },
                component: () => import(`../views/userManagement/userManagement.vue`),
            },
            {
                path: '/logManagement',
                name: 'logManagement', //日志管理
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                    tabName: '日志管理',
                },
                component: () => import(`../views/logManagement/logManagement.vue`),
            },
            {
                path: '/problemDiscovery',
                name: 'problemFound', //问题发现
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                    tabName: '问题发现',
                },
                component: () => import(`../views/problemFound/problemFound.vue`),
            },
            {
                path: '/problemFoundAdd',
                name: 'problemFoundAdd', //问题发现
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                    tabName: '问题发现',
                },
                component: () => import(`../views/problemFound/problemFoundAdd.vue`),
            },
            {
                path: '/questionCategory',
                name: 'proClassification', //问题分类
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                    tabName: '问题分类',
                },
                component: () => import(`../views/proClassification/proClassification.vue`),
            },
            {
                path: '/proClassificationAdd',
                name: 'proClassificationAdd', //问题分类
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                    tabName: '问题分类',
                },
                component: () => import(`../views/proClassification/proClassificationAdd.vue`),
            },
            {
                path: '/evaluation',
                name: 'proAssessment', //问题评估
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                    tabName: '问题评估',
                },
                component: () => import(`../views/proAssessment/proAssessment.vue`),
            },
            {
                path: '/proAssessmentAdd',
                name: 'proAssessmentAdd', //问题评估
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                    tabName: '问题评估',
                },
                component: () => import(`../views/proAssessment/proAssessmentAdd.vue`),
            },
            {
                path: '/issueReview',
                name: 'proAudit', //问题评估
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                    tabName: '问题审核',
                },
                component: () => import(`../views/proAudit/proAudit.vue`),
            },
            {
                path: '/proAuditAdd',
                name: 'proAuditAdd', //问题审核
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                    tabName: '问题审核',
                },
                component: () => import(`../views/proAudit/proAuditAdd.vue`),
            },
            {
                path: '/data_maintenance',
                name: 'dataMaintenance', //数据维护
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                    tabName: '数据维护',
                },
                // component: () => import(`../views/dataMaintenance/dataMaintenance.vue`),理论制度成果
                // component: () => import(`../views/dataMaintenance/starRating.vue`), //星级评价
                component: () => import(`../views/dataMaintenance/dataMaintenanceList.vue`),
            },
            {
                path: '/dataMaintenanceAdd',
                name: 'dataMaintenanceAdd', //数据维护
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                    tabName: '数据维护',
                },
                component: () => import(`../views/dataMaintenance/dataMaintenanceAdd.vue`),
            },
            {
                path: '/index',
                name: 'index', // 首页配置
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                    tabName: '首页配置',
                },
                component: () => import('@/views/index/index'),
            },
            {
                path: '/menu',
                name: 'menu', // 菜单管理
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                    tabName: '菜单管理',
                },
                component: () => import(`../views/config/menu.vue`),
            },
            {
                path: '/cockpit_configuration',
                name: 'cockpit_configuration', // 驾驶舱配置
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                    tabName: '统一配置驾驶舱',
                },
                component: () => import(`../views/cockpitConfiguration/cockpitConfiguration.vue`),
            },
            {
                path: '/activity_statistics',
                name: 'activity_statistics', // 菜单管理
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                    tabName: '活跃度统计',
                },
                component: () => import(`../views/activityStatistics/activityStatistics.vue`),
            },
            {
                path: '/cohesion_index',
                name: 'cohesion_index', // 同心指数
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                    tabName: '同心指数',
                },
                component: () => import(`../views/cohesionIndex/cohesionIndex.vue`),
            },
            {
                path: '/blank',
                name: 'blank', // 空白
                meta: {
                    keepAlive: false, //设置页面是否需要使用缓存
                    tabName: '空白',
                },
                component: () => import(`../views/blank.vue`),
            },
        ],
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            keepAlive: false, //设置页面是否需要使用缓存
        },
        component: () => import(`../views/login.vue`),
    },
    {
        path: '/Error',
        name: 'Error',
        meta: {
            keepAlive: false, //设置页面是否需要使用缓存
        },
        component: () => import(`@/views/Error`),
    },
];
console.log('routes', routes);
const router = createRouter({
    history: createWebHashHistory(),
    routes,
});
// 判断是否是第一次进系统
let toFalge = false;

router.beforeEach((to, from, next) => {
    const code = localStorage.getItem('code');
    // console.log('store.state.user.menus[0].path', store.state.user.menus[0].path);
    // console.log('code', code);
    // 如果有oepnUrl证明是dd端存储需要打开的url,进行拦截
    console.log('localStorage.removeItem(1111)', localStorage.oepnUrl);

    if (localStorage.oepnUrl) {
        let url = localStorage.oepnUrl;
        localStorage.removeItem('oepnUrl');
        console.log('localStorage.removeItem(222)', localStorage.oepnUrl);
        location.replace(url);
        return;
    }
    if (!code) {
        sessionStorage.clear();
        window.location.replace(
            redirectUrl || 'https://sztzyh.zj.gov.cn/enduser/sp/sso/tzjsplugin_jwt4?enterpriseId=tzjs'
        );
    } else {
        next();
        // 通过sessionStorage和toFalge判断 第一次进系统加载第一个menus菜单界面
        // if (!toFalge && !sessionStorage.toFalge) {
        //     setTimeout(() => {
        //         console.log('store.state.user.menus', store.state.user.menus);
        //         toFalge = true;
        //         sessionStorage.setItem('toFalge', true);
        //         next({ path: store.state.user.menus[0].path });
        //     }, 2000);
        // } else {
        //     next();
        // }
    }
});

export default router;

// 判断用户是否是钉钉用户
let isDD = regMobile('dingtalkWin');
console.log('isDD', isDD);
if (isDD) {
    sessionStorage.setItem('isDD', isDD);
    dd.getAuthCode({ corpId: '' })
        .then((result) => {
            console.log('钉钉信息', result.auth_code);
            if (result) {
                axios({
                    method: 'get',
                    url: '/h5/sso/ddLogin',
                    params: {
                        authCode: result.auth_code,
                    },
                }).then((res) => {
                    console.log('钉钉登录', res);
                    localStorage.setItem('token', res.token);
                    axios.defaults.headers.common['token'] = res.token;
                    store.dispatch('user/userinfo');
                    // router.push('/home');
                });
            }
        })
        .catch((err) => {
            console.log('钉钉catch', err);
        });
}
