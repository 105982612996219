// 任务执行
import request from '@/utils/request';
import { BASE_URL } from '../config.js';
let ModuleName = 'frontIndexUnit';
export default {
    add(data) {
        // 新增任务
        return request.post(`${BASE_URL}/${ModuleName}/add`, data);
    },
    delete(id) {
        //删除任务
        return request.get(`${BASE_URL}/${ModuleName}/delete`, { params: { id: id } });
    },
    update(data) {
        // 修改任务
        return request.post(`${BASE_URL}/${ModuleName}/update`, data);
    },
    fillIndexUnitDoneNumber(data) {
        // 修改任务
        return request.post(`${BASE_URL}/${ModuleName}/fillIndexUnitDoneNumber`, data);
    },
    updateFillIndexUnitDoneNumber(data) {
        // 修改任务
        return request.post(`${BASE_URL}/${ModuleName}/updateFillIndexUnitDoneNumber`, data);
    },
    getById(id) {
        //	根据二级任务id查询牵头单位
        return request.get(`${BASE_URL}/${ModuleName}/getById`, { params: { id: id } });
    },
    tasksToBePerformed(query) {
        //分页查询任务
        return request.get(`${BASE_URL}/${ModuleName}/tasksToBePerformed`, { params: query });
    },
    getPage(query) {
        //分页查询任务
        return request.get(`${BASE_URL}/${ModuleName}/getPage`, { params: query });
    },
};
