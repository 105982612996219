// 任务管理接口
import request from '@/utils/request'
import { ADMIN_URL } from '../config.js'
let ModuleName = 'system/menu' //任务
export default {
	add(data) {
		// 新增
		return request.post(`${ADMIN_URL}/${ModuleName}/add`, data)
	},
	delete(id) {
		// 删除
		return request.get(`${ADMIN_URL}/${ModuleName}/delete`, { params: { id: id } })
	},
	update(data) {
		// 修改
		return request.post(`${ADMIN_URL}/${ModuleName}/update`, data)
	},
	getById(menuId) {
		// 根据id查询
		return request.get(`${ADMIN_URL}/${ModuleName}/${menuId}`)
	},
	getByParentId(query) {
		// 根据父id查询子
		return request.get(`${ADMIN_URL}/${ModuleName}/getByParentId`, { params: query })
	},
	list(query) {
		//  获取菜单列表
		return request.get(`${ADMIN_URL}/${ModuleName}/list`, { params: query })
	},
	getRoleMenuTree(roleId) {
		return request.get(`${ADMIN_URL}/${ModuleName}/roleMenuTreeselect/${roleId}`)
	},
}
