// 模块化api自动挂载，查找当前目录下的 modules 目录中的全部 js 文件
const files = require.context('./modules', true, /\.js$/)
let apiMaps = {}
files.keys().forEach((key) => {
	if (files(key).default) {
		// let module = key.replace(/(\.\/|\.js)/g, '');
		// apiMaps[module] = Object.assign({}, files(key).default);
		apiMaps[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
	}
})

export default apiMaps
