// 审核管理
import request from '@/utils/request';
import { BASE_URL } from '../config.js';
let ModuleName = 'frontVerify';
export default {
    add(data) {
        // 新增
        return request.post(`${BASE_URL}/${ModuleName}/add`, data);
    },
    verify(data) {
        // 审核
        return request.post(`${BASE_URL}/${ModuleName}/verify`, data);
    },
    delete(id) {
        // 删除
        return request.get(`${BASE_URL}/${ModuleName}/delete`, { params: { id: id } });
    },
    update(data) {
        // 修改
        return request.post(`${BASE_URL}/${ModuleName}/update`, data);
    },
    getById(id) {
        // 根据id查询
        return request.get(`${BASE_URL}/${ModuleName}/getById`, { params: { id: id } });
    },
    getPage(query) {
        // 分页查询
        return request.get(`${BASE_URL}/${ModuleName}/getPage`, { params: query });
    },
};
