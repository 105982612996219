import api from '@/api';
import { GetQueryString } from '@/utils/common';

const state = {
    roleId: null,
    roles: [],
    menus: [],
    token: '',
    userinfo: {},
    currentNodeId: [], //当前登录人员流程节点id
    redirectUrlDev:
        'http://101.201.223.108/enduser/sp/sso/testplugin_jwt11?enterpriseId=test&redirect_uri=http://192.168.1.130:8032?false?workbench?', //开发环境
    redirectUrlPro:
        'https://sztzyh.zj.gov.cn/enduser/sp/sso/tzjsplugin_jwt4?enterpriseId=tzjs&redirect_uri=https://sztz.zj.gov.cn?true?index?', //线上环境
    // redirectUrlPro: 'http://101.201.223.108/enduser/sp/sso/testplugin_jwt11?enterpriseId=test', //测试环境
    //    'http://101.201.223.108/enduser/sp/sso/testplugin_jwt11?enterpriseId=test&redirect_uri=http://124.220.180.189?false?workbench?',//公网环境
    baseURLToken: process.env.VUE_APP_API_SERVER_IP, //请求token接口url
    userBtns: {}, //用户的按钮权限
    sub_menu: {}, //用户的子菜单权限
    unitData: [], //单位管理数据
};
const mutations = {
    set_roles(state, data) {
        state.roles = data;
    },
    set_menu(state, data) {
        state.menus = data;
    },
    set_userinfo(state, data) {
        state.userinfo = data;
    },
    set_token(state, data) {
        state.token = data;
    },
    set_currentNodeId(state, data) {
        console.log(data);
        state.currentNodeId = data;
    },
    set_btns(state, data) {
        state.userBtns = data;
    },
    set_sub_menu(state, data) {
        state.sub_menu = data;
    },
    set_unit_data(state, data) {
        state.unitData = data;
    },
};

const actions = {
    async userinfo({ state, commit }) {
        console.log(state);

        let code = localStorage.code || '12';
        let baseURL = process.env.NODE_ENV === 'development' ? state.baseURLToken : '';
        let idToken = new URLSearchParams(location.search).get('id_token');
        let redirect = location.search.split('?')[1];
        let type = location.search.split('?')[2];
        commit('set_currentNodeId', ['2', '3', '4', '5', '6']);
        // await axios({
        //     method: 'post',
        //     url: `/front/ssoLogin/`,
        //     data: { idToken, type, redirect },
        //     baseURL: baseURL,
        // }).then((res) => {
        //     console.log('user.js', res);
        //     res.user && commit('set_userinfo', res.user);
        // });

        let iconMap = {
            任务管理: 'Collection',
            任务上报: 'Collection',
            任务审核: 'Document',
            任务执行: 'EditPen',
            消息中心: 'BellFilled',
            菜单管理: 'Expand',
            日志管理: 'Menu',
            统一用户: 'User',
            问题管理: 'ZoomIn',
            问题发现: 'ZoomIn',
            问题分类: 'List',
            问题评估: 'Tickets',
            问题审核: 'Document',
            数据维护: 'Tools',
            驾驶舱配置: 'Hide',
            活跃度统计: 'Histogram',
            同心指数: 'TrendCharts',
        };
        // 外跳链接
        let pathMap = {
            统一用户: {
                url: process.env.VUE_APP_API_USER_URL,
                path: '/',
            },
        };
        //动态获取menu,只渲染type==='menu'
        let allMenus = state.userinfo.permissionList
            .filter((e) => e.type === 'menu')
            .map((item) => {
                // 统一用户为外跳链接,如果有路由会导致原项目节目空白
                if (item.name === '统一用户') {
                    item.permissionValue = '';
                }
                (item.url = pathMap[item.name] ? pathMap[item.name].url : null), (item.icon = iconMap[item.name]);
                return item;
            });
        // 获取button权限,通过判断type=button来识别按钮
        let btns = {};
        state.userinfo.permissionList.map((item) => {
            item.type === 'button' ? (btns[item.permissionValue] = true) : '';
            item.children &&
                item.children.map((menu) => {
                    menu.type === 'button' ? (btns[menu.permissionValue] = true) : '';
                    menu.children &&
                        menu.children.map((btn) => {
                            btn.type === 'button' ? (btns[btn.permissionValue] = true) : '';
                            btn.children &&
                                btn.children.map((btn1) => {
                                    btn1.type === 'button' ? (btns[btn1.permissionValue] = true) : '';
                                });
                        });
                });
        });
        // 获取sub_menu权限,通过判断type=sub_menu来识别按钮
        let sub_menu = {};
        state.userinfo.permissionList.map((item) => {
            item.type === 'sub_menu' ? (sub_menu[item.permissionValue] = true) : '';
            item.children &&
                item.children.map((menu) => {
                    menu.type === 'sub_menu' ? (sub_menu[menu.permissionValue] = true) : '';
                    menu.children &&
                        menu.children.map((btn) => {
                            btn.type === 'sub_menu' ? (sub_menu[btn.permissionValue] = true) : '';
                        });
                });
        });
        commit('set_sub_menu', sub_menu);
        commit('set_menu', allMenus);
        commit('set_btns', btns);
        console.log('allMenus', allMenus);
        console.log('set_sub_menu', sub_menu);
        console.log('btns', btns);
    },
    getUnitData({ state, commit }) {
        axios({
            url: '/front/sysOrganization/getDeptTree',
            method: 'get',
            params: {
                parentExternalId: 'main',
            },
        }).then((res) => {
            commit('set_unit_data', res);
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
