// 任务执行
import request from '@/utils/request';
import { BASE_URL } from '../config.js';
let ModuleName = 'frontProblem';
export default {
    addAsDraft(data) {
        //新增为草稿
        return request.post(`${BASE_URL}/${ModuleName}/addAsDraft`, data);
    },
    addAndPost(data) {
        //保存提交
        return request.post(`${BASE_URL}/${ModuleName}/addAndPost`, data);
    },
    delete(id) {
        //删除任务
        return request.get(`${BASE_URL}/${ModuleName}/delete`, { params: { id } });
    },
    verifyGetPage(id) {
        //获取流转记录
        return request.get(`${BASE_URL}/${ModuleName}Verify/getPage`, { params: { size: -1, problemId: id } });
    },
    update(data) {
        // 修改任务
        return request.post(`${BASE_URL}/${ModuleName}/update`, data);
    },
    fillIndexUnitDoneNumber(data) {
        // 修改任务
        return request.post(`${BASE_URL}/${ModuleName}/fillIndexUnitDoneNumber`, data);
    },
    getById(id) {
        //	根据二级任务id查询牵头单位
        return request.get(`${BASE_URL}/${ModuleName}/getById`, { params: { id } });
    },
    getPage(query) {
        //分页查询任务
        return request.get(`${BASE_URL}/${ModuleName}/getPage`, { params: query });
    },
};
