// 部门信息管理
import request from '@/utils/request'
import { ADMIN_URL } from '../config.js'
let ModuleName = 'system/role'
export default {
	add(data) {
		// 新增角色
		return request.post(`${ADMIN_URL}/${ModuleName}`, data)
	},
	delete(id) {
		//删除角色
		return request.del(`${ADMIN_URL}/${ModuleName}/delete/${id}`)
	},
	update(data) {
		// 修改角色
		return request.put(`${ADMIN_URL}/${ModuleName}`, data)
	},
	getById(id) {
		return request.get(`${ADMIN_URL}/${ModuleName}/${id}`)
	},
	getRoleResource(id) {
		return request.get(`${ADMIN_URL}/${ModuleName}/${id}`)
	},
	getUserByDeptId(query) {
		// 根据id查询
		return request.get(`${ADMIN_URL}/${ModuleName}/getUserByDeptId`, { params: query })
	},
	list(query) {
		// 获取角色列表
		return request.get(`${ADMIN_URL}/${ModuleName}/list`, { params: query })
	},
	allocatedList() {
		return request.get(`${ADMIN_URL}/${ModuleName}/list`, { params: query })
	},
	authUser(query) {
		//批量选择用户授权
		return request.put(`${ADMIN_URL}/${ModuleName}/authUser/selectAll`, {}, { params: query })
	},
	unAuthUser(query) {
		//批量取消授权用户
		return request.put(`${ADMIN_URL}/${ModuleName}/authUser/cancelAll`, {}, { params: query })
	},
	dataScope(data) {
		return request.put(`${ADMIN_URL}/${ModuleName}/dataScope`, data)
	},
}
