import axios from 'axios';
import Qs from 'qs';
import store from '@/store';
import router from '@/router';
import { ElMessage } from 'element-plus';
const errorCodeDict = {
    200: '服务器成功返回请求数据',
    201: '新建或修改数据成功',
    202: '一个请求已经进入后台排队(异步任务)',
    204: '删除数据成功',
    400: '发出信息有误',
    401: '用户没有权限(登录过期)',
    403: '用户得到授权，但是访问是被禁止的',
    404: '访问资源不存在',
    406: '请求格式不可得',
    410: '请求资源被永久删除，且不会被看到',
    500: '服务器发生错误',
    502: '网关错误',
    503: '服务不可用，服务器暂时过载或维护',
    504: '网关超时',
};

let config = {
    baseURL: process.env.NODE_ENV === 'development' ? '/' : '',
    timeout: 30000,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    },
};
const http = axios.create(config);

// 数据请求拦截
http.interceptors.request.use(
    config => {
        if(config.url.indexOf('/zty-api')==0){
            // 活跃度需要/zty-api
            config.url = config.url;
        }else{
            config.url = process.env.VUE_APP_API_BASE_URL + config.url;
        }

        let token = window.localStorage.getItem('token');
        // console.log('token', token);
        if (token) {
            config.headers['Authorization'] = token;
        } else {
            // router.push('login')
        }
        // 3. 根据请求方法，序列化传来的参数，根据后端需求是否序列化
        config.data = JSON.stringify(config.data);
        config.paramsSerializer = function (params) {
            return Qs.stringify(params, { arrayFormat: 'repeat' });
        };

        return config;
    },
    error => {
        console.log('request error', error);
        return Promise.reject(error);
    }
);

// 请求响应返回数据拦截
http.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return Promise.resolve(response.data);
        }
    },
    error => {
        throw new Error(error.response);
        if (error.response && error.response.status) {
            let code = error.response.status;
            switch (code) {
                case 401: {
                    store.dispatch('user/setToken');
                    break;
                }
                default: {
                    if (code in errorCodeDict) {
                        ElMessage({
                            type: 'error',
                            message: errorCodeDict[code],
                        });
                    }
                }
            }
        }
        return Promise.reject(error.response);
    }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        http.get(url, {
            params: params,
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data);
            });
    });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, QS.stringify(params))
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data);
            });
    });
}

export default http;
