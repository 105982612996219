// 任务执行
import request from '@/utils/request';
import { BASE_URL } from '../config.js';
let ModuleName = 'admSztzNjlzsDqdfqk';
export default {

    getPage(query) {
        //分页查询任务
        return request.get(`${BASE_URL}/${ModuleName}/getPage`, { params: query });
    },
};
