export function empty(v) {
    switch (typeof v) {
        case 'undefined':
            return true;
        case 'string':
            if (v.trim().length == 0) return true;
            break;
        case 'boolean':
            if (!v) return true;
            break;
        case 'object':
            if (null === v) return true;
            if (undefined !== v.length && v.length == 0) return true;
            for (var k in v) {
                return false;
            }
            return true;
            break;
    }
    return false;
}

// 验证常用的设备
export function regMobile(type, str = navigator.userAgent) {
    let rule = {
        mobile: /AppleWebKit.*(Mobile|MQQBrowser)+/, // 移动端
        ios: /\(i[^;]+;( U;)? CPU.+Mac OS X/, // ios
        iphone: /.*iPhone.*/, // iPhone
        android: /(.*Linux+.*Android+.*)|(.*Android+.*Linux+.*)/, // Android
        weibo: /.*Weibo.*/, // 微博
        wechat: /.*(MicroMessenger|micromessenger)+.*/, // 微信
        pcwechat: /.*WindowsWechat+.*/, // 微信
        dingtalk: /.*DingTalk.*/, // 钉钉
        Alipay: /.*Alipay.*/, // 支付宝
        AliApp: /.*AliApp.*/, // 阿里系APP webview
        chrome: /.*(Chrome)\/([\w.]+).*/, // chrome
        lark: /.*Lark.*/, // 飞书 Lark
        // pc端
        dingtalkWin: /.*dingtalk-win.*/, // 钉钉 windows
        ie: /.*Trident.*/, // IE内核
        opera: /.*Presto.*/, // opera内核
        webKit: /.*AppleWebKit.*/, // 苹果、谷歌内核
    };
    return rule[type].test(str.trim());
}

//中国标准时间转换年月日
export function formatDateTime(date) {
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? '0' + m : m;
    var d = date.getDate();
    d = d < 10 ? '0' + d : d;
    var h = date.getHours();
    var minute = date.getMinutes();
    minute = minute < 10 ? '0' + minute : minute;
    return y + '-' + m + '-' + d;
    // return y + '-' + m + '-' + d + ' ' + h + ':' + minute;
}

// 截取获取url的token
export function GetQueryString(key) {
    let obj = {},
        a = '';
    a = window.location.search.substr(1) || (a = window.location.hash.split('?')[1]);
    if (a) {
        a.split(/&/g).forEach(function (item) {
            obj[(item = item.split('='))[0]] = item[1];
        });
    }
    // console.log(a, obj[key]);
    return obj[key];
}
