import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import getters from './getters'
// 引入多个模块
const moduleFiles = require.context('./modules', true, /\.js$/)
const modules = moduleFiles.keys().reduce((modules, modulePath) => {
	const storeName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
	modules[storeName] = moduleFiles(modulePath).default
	return modules
}, {})

// 创建store
const store = createStore({
	modules,
	getters,
	//持久化数据
	plugins: [
		createPersistedState({
			key: 'vuex', // 存储是的名字
			// 也可以是sessionstorage
			storage: window.sessionstorage,
		}),
	],
})

export default store
