<template>
    <router-view />
</template>
<script setup>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
// import {initRouter} from "@/router/index"
const store = useStore();
onMounted(async () => {
    // await store.dispatch('user/userinfo');
    await store.dispatch('user/getUnitData');
    // initRouter()
});
</script>
<style lang="stylus">
#app
    font-family Avenir, Helvetica, Arial, sans-serif
    -webkit-font-smoothing antialiased
    -moz-osx-font-smoothing grayscale
    // text-align center
    color #333333
    width: 100vw;
    height: 100vh;
    background: #f5f5f5;
html,body{
    margin: 0
    padding 0
}
</style>
