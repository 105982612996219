// 审核管理
import request from '@/utils/request';
import { BASE_URL } from '../config.js';
// let ModuleName = 'frontVerify';
export default {
    getByParentExternalId(id) {
        // 牵头单位
        return request.post(`${BASE_URL}/sysOrganization/getByParentExternalId`, { params: { parentExternalId: id } });
    },
    getPraentIdByExternalId(id) {
        return request.get(`${BASE_URL}/sysOrganization/getByExternalId`, { params: { externalId: id } });
    },
};
