// 任务执行
import request from '@/utils/request';
import { BASE_URL } from '../config.js';
let ModuleName = 'frontAchievement';
export default {
    add(data) {
        // 新增
        return request.post(`${BASE_URL}/${ModuleName}/add`, data);
    },
    update(data) {
        // 编辑
        return request.post(`${BASE_URL}/${ModuleName}/update`, data);
    },
    delete(id) {
        //删除
        return request.get(`${BASE_URL}/${ModuleName}/delete`, { params: { id: id } });
    },
    getByType(type) {
        //查询数字统战核心指标表覆盖率
        return request.get(`${BASE_URL}/${ModuleName}/getByType`, { params: { type } });
    },
    getById(id) {
        // 根据id查询
        return request.get(`${BASE_URL}/${ModuleName}/getById`, { params: { id: id } });
    },
    getPage(query) {
        //分页查询任务
        return request.get(`${BASE_URL}/${ModuleName}/getPage`, { params: query });
    },
};
