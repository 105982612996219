<template>
    <div class="home">
        <header>
            <!-- <div class="logo"></div> -->
            <div class="title">数字统战</div>
            <div class="user">
                <span class="btn" @click="toScreen(handBookUrl)">操作手册下载</span>
                <span
                    class="btn"
                    v-if="userBtns.cockpit && showBtn"
                    @click="toScreen('https://sztzyh.zj.gov.cn/enduser/sp/sso/tzjsplugin_jwt7?enterpriseId=tzjs')"
                    >数据共享平台</span
                >
                <span class="btn" v-if="userBtns.cockpit" @click="toCockpit">进入驾驶舱</span>
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="userInfo.sysOrganization ? userInfo.sysOrganization.organizationName : ''"
                    placement="bottom"
                >
                    <el-icon @click="showOrgDialog" class="OfficeBuilding"><OfficeBuilding /></el-icon>
                </el-tooltip>
                <el-badge :value="total" class="item">
                    <div class="new-msg">
                        <i @click="showMsg = !showMsg" class="el-icon-bell"></i>
                        <el-icon @click="showMsg = !showMsg"><bell /></el-icon>
                        <transition name="fade">
                            <div v-if="showMsg" class="msg-box">
                                <div class="postion">
                                    <div class="title">
                                        <div>消息提醒</div>
                                        <i @click="showMsg = !showMsg" class="el-icon-close"></i>
                                        <el-icon @click="showMsg = !showMsg"><close /></el-icon>
                                    </div>
                                    <div class="msg-list" v-if="msgList.length > 1">
                                        <div
                                            class="item"
                                            v-for="(item, index) in msgList"
                                            :key="index"
                                            @click="changeType(item, item.messageType)"
                                        >
                                            <div class="item-top">
                                                <div class="tag tag1" v-if="item.messageType == 1">执行通知</div>
                                                <div class="tag tag2" v-if="item.messageType == 2">审核提醒</div>
                                                <div class="tag tag3" v-if="item.messageType == 3">执行预警</div>
                                                <div class="tag tag4" v-if="item.messageType == 4">审核提醒</div>
                                                <div class="time">{{ item.sendTime }}</div>
                                            </div>
                                            <div class="content">
                                                <div class="text">
                                                    {{
                                                        item.sendPersonName
                                                    }}发来一条重大任务指标等待您的审批，请及时处理
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="msg-list nodata" v-else>暂无数据</div>
                                    <div class="more" @click="router.push('/messageCenter')">
                                        <div>查看更多</div>
                                    </div>
                                    <div class="up"></div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </el-badge>
                <span class="userName">你好,{{ $store.state.user.userinfo.displayName }}</span>
                <!-- dd客户端隐藏退出按钮 -->
                <!-- <span v-if="!isDD" class="logout" @click="logout"> 退出 </span> -->
                <span class="logout" @click="logout"> 退出 </span>
            </div>
        </header>
        <section>
            <div class="menu">
                <el-menu
                    background-color=""
                    text-color="#333"
                    active-text-color="#1591FF"
                    :default-active="defaultActive"
                    router
                    @select="menuSelect"
                    unique-opened
                >
                    <div
                        @click="menuChange(item)"
                        :index="item.path"
                        v-for="item in $store.getters.menus"
                        :key="item.path"
                    >
                        <el-sub-menu
                            :index="item.permissionValue"
                            v-if="item.children && item.children.some((menu) => menu.type === 'two_menu')"
                        >
                            <template #title>
                                <el-icon v-if="item.icon"> <component :is="item.icon" /> </el-icon>
                                <span>{{ item.name }}</span>
                            </template>
                            <el-menu-item-group>
                                <el-menu-item
                                    v-for="(menu, index) in item.children"
                                    :key="index"
                                    :index="menu.permissionValue"
                                    >{{ menu.name }}</el-menu-item
                                >
                            </el-menu-item-group>
                        </el-sub-menu>
                        <el-menu-item :index="item.permissionValue" v-else>
                            <el-icon v-if="item.icon"> <component :is="item.icon" /> </el-icon>
                            <span>{{ item.name }}</span>
                        </el-menu-item>
                    </div>
                </el-menu>
            </div>
            <div class="content router">
                <div class="wrapper">
                    <el-tabs class="tabs" v-model="activeTab" type="card" @tab-click="tabClick" @tab-remove="tabRemove">
                        <el-tab-pane
                            v-for="(item, key) in tabList"
                            :key="key"
                            :label="item.title || item.meta.tabName"
                            :name="item.name"
                            :closable="activeTab !== item.name"
                            :ref="item.ref"
                        >
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <router-view v-slot="{ Component }">
                    <keep-alive :include="keep">
                        <component class="view" :key="$route.guid" :is="Component" v-if="route.meta.keepAlive" />
                    </keep-alive>
                    <component class="view" :key="$route.guid" :is="Component" v-if="!route.meta.keepAlive" />
                </router-view>
            </div>
        </section>

        <ChangeOrg v-if="changeOrgDialog" @close="changeOrgClose" />
    </div>
</template>

<script setup>
import { Document, Menu as IconMenu, Location, Setting } from '@element-plus/icons-vue';
import { ref, reactive, computed, watch, onMounted, onUnmounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { ElMessage, ElMessageBox } from 'element-plus';
import { regMobile } from '@/utils/common';
import dd from 'gdt-jsapi';
// 切换组织机构
import ChangeOrg from '@/views/home/dialog/changeOrg';
const route = useRoute();
const router = useRouter();
const store = useStore();
const defaultActive = computed(() => '/' + route.name);
console.log(route, defaultActive.value);
let tabList = ref([]);
let activeTab = ref('');

let showMsg = ref(false);
let changeOrgDialog = ref(false);
let msgList = ref([]);
let total = ref(0);
let tabs = ref([]);
let tabIndex = ref(1);
let showBtn = ref(false);
let handBookUrl = ref('');
let tabsPath = ref([
    {
        name: '1',
        path: '/reportOnTheMission',
    },
]);

function logout() {
    axios({
        url: '/front/leave',
        method: 'get',
        params: {},
    });
    localStorage.clear();
    location.replace(process.env.VUE_APP_API_LOGINOUT_URL);
}
function getInfo() {
    axios.get(`/front/frontMessage/getPage?size=5&page=1`).then((res) => {
        msgList.value = res.records;
        total.value = res.total;
    });
}
async function menuSelect(index, indexPath, item, routeResult) {}
function menuChange(item) {
    console.log(item);
    //
    if (item.url) {
        // 判断是dd浏览器还是普通浏览器
        const isDD = regMobile('dingtalkWin');
        if (isDD) {
            dd.openLink({
                url: `${item.url}?ddtab=true`,
            })
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {});
        } else {
            window.open(item.url);
        }
    }
}
// 进入驾驶舱
async function toCockpit() {
    // 判断是dd浏览器还是普通浏览器
    const isDD = regMobile('dingtalkWin');
    let type = isDD ? 'zzd' : null;
    const res = await axios({
        url: '/front/sysUser/enterTheLargeScreen',
        method: 'get',
        params: {
            type,
        },
    });
    if (res) {
        toScreen(res.msg);
    }
}
function toScreen(url) {
    // 判断是dd浏览器还是普通浏览器
    const isDD = regMobile('dingtalkWin');

    if (isDD) {
        if (url.indexOf('数字统战综合应用用户操作手册2022') > 0) {
            dd.openLink({
                url: `${url}?ddtab=true`,
            })
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {});
        } else {
            // dd端,设置需要新开标签页的url
            localStorage.setItem('oepnUrl', url);
            dd.openLink({
                url: `${url}?ddtab=true`,
                // url: `https://sztzyh.zj.gov.cn/enduser/sp/sso/tzjsplugin_jwt4?enterpriseId=tzjs&redirect_uri=https://sztz.zj.gov.cn?false?workbench?ddtab=true`,
                // url: `https://sztz.zj.gov.cn/?ddtab=true`,
            })
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {});
        }
    } else {
        window.open(url);
    }
}

function tabRemove(targetName) {
    let tabs = tabList.value; //当前显示的tab数组
    let activeName = activeTab.value; //点前活跃的tab
    //如果当前tab正活跃 被删除时执行
    // if (tabs.length > 1) {
    if (activeName == targetName) {
        tabs.forEach((tab, index) => {
            if (tab.name == targetName) {
                let nextTab = tabs[index + 1] || tabs[index - 1];
                if (nextTab) {
                    activeName = nextTab.name;
                    tabClick(nextTab);
                }
            }
        });
    }
    activeTab.value = activeName;
    tabList.value = tabs.filter((tab) => tab.name !== targetName);
    //在tabsPath中删除当前被删除tab的path
}
function tabClick(tab) {
    let val = tabsPath.value.filter((item) => tab.paneName == item.name);
    router.replace(val[0].path);
}
function guid(length) {
    return 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.slice(0, length).replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16).toUpperCase();
    });
}
function showOrgDialog() {
    if (userInfo.value.sysOrganizationList.length === 1) {
        ElMessage.error('当前用户仅有一个组织机构无法更换!');
        return;
    }
    changeOrgDialog.value = true;
}
function changeOrgClose() {
    changeOrgDialog.value = false;
}
watch(
    route,
    (newRoute, oldRoute) => {
        // console.log(newRoute, newRoute.fullPath, oldRoute, tabList.value, activeTab.value, Object.values(tabList.value));
        let flag = true; //判断是否需要新增页面
        const path = newRoute.path;
        // ;
        // console.log(tabList.value, tabs.value);
        if (Object.keys(newRoute.meta).length != 0) {
            for (let i = 0; i < tabList.value.length; i++) {
                //首页不判断 如果页面已存在，则直接定位当页面，否则新增tab页面
                if (tabList.value[i].title == newRoute.meta.tabName) {
                    activeTab.value = tabList.value[i].name; //定位到已打开页面
                    flag = false;
                    break;
                }
            }
        }
        if (flag) {
            //获得路由元数据的name和组件名
            const thisName = newRoute.meta.tabName;
            const thisComp = newRoute.meta.tabName;
            //对tabs的当前激活下标和tabs数量进行自加
            let newActiveIndex = ++tabIndex.value + '';
            //动态双向追加tabs
            tabList.value.push({
                title: thisName,
                name: String(newActiveIndex),
                closable: true,
                ref: 'tabs',
                content: thisComp,
            });
            activeTab.value = newActiveIndex;
            /*
             * 当添加tabs的时候，把当前tabs的name作为key，path作为value存入tabsPath数组中
             * key:tabs的name
             * value:tabs的path
             * {
             *   key: name,
             *   value: path
             * }
             * ///后面需要得到当前tabs的时候可以通过当前tabs的name获得path
             * */
            if (tabsPath.value.indexOf(path) == -1) {
                tabsPath.value.push({
                    name: newActiveIndex,
                    path: path,
                });
            }
        }
    },
    { deep: true }
);
// 获取按钮权限
const userBtns = computed(() => {
    return store.state.user.userBtns;
});
// 获取用户信息
const userInfo = computed(() => {
    return store.state.user.userinfo;
});
onMounted(() => {
    console.log('process.env.VUE_APP_API_ENV_IRONMENT', process.env.VUE_APP_API_ENV_IRONMENT);
    // 内网隐藏数字共享平台按钮
    showBtn.value = process.env.VUE_APP_API_ENV_IRONMENT !== 'intranet';
    handBookUrl.value = process.env.VUE_APP_API_HANDBOOK;
    const isDD = regMobile('dingtalkWin');
    getInfo();
    let routes = { ...route };
    tabList.value.push(
        Object.assign({}, routes, {
            closable: true,
            ref: 'tabs',
            name: 1,
            title: route.meta.tabName,
        })
    );
    activeTab.value = 1;
    setTimeout(() => {
        getInfo();
    }, 5 * 60 * 1000);
    window.onbeforeunload = function () {
        axios({
            url: '/front/leave',
            method: 'get',
            params: {},
        });
    };

    //记录用户未操作时间,超过五分钟自动请求接口
    let count = 0;
    setInterval(() => {
        count++;
        if (count >= 300) {
            axios({
                url: '/front/leave',
                method: 'get',
                params: {},
            });
            count = 0;
        }
    }, 1000);
    window.addEventListener('mousemove', function (e) {
        count = 0;
    });
});
</script>
<style lang="stylus" scoped>
.home {
	height: 100%;
	header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: url('~@/assets/img/head.png') no-repeat;
		background-size: 100% 100%;
		color: #fff;
		line-height: 1;
		padding: 0 20px;
		height: 50px;
		background-color: #fff;
		.logout {
			margin-left: 8px;
			cursor: pointer;
		}
		.logo {
			width: 34.69px;
            height: 20px;
			// background: url('~@/assets/img/logo.png') no-repeat;
			background-size: 100% 100%;
			margin-right: 10px;
		}
		.title {
			flex: 1;
			font-family: YouSheBiaoTiHei;
			font-size: 20px;
            height: 22px;
			color: #FFFFFF;
			text-align: left;
		}
		.user {
			font-size: 14px;
			.btn {
				width: 86px;
				height: 28px;
				padding: 5px 8px;
				border: 1px solid #FFFFFF;
				border-radius: 4px;
				margin-right: 16px;
				cursor: pointer;
			}
			.el-icon {
				font-size: 18px;
				vertical-align: -4px;
				cursor: pointer;
			}
			.userName {
				margin-left: 16px;
			}
		}
	}
	section {
		display: flex;
		height: calc(100% - 50px);
		.menu {
			width: 200px;
			background: #FFFFFF;
			font-weight: 400;
			font-size: 14px;
		}
		.content {
			padding: 24px 60px;
		}
		.router {
			flex: 1;
			background: #F6F7F8;
			padding: 14px 24px 20px;
			box-sizing: border-box;
			overflow-y: auto;
		}
	}
}
</style>
<style lang="stylus" scoped>
.OfficeBuilding{
    margin 6px 0 0 0
}
.new-msg {
	position: relative;
    margin: 0 0 0 16px
	.msg-box {
		z-index:10000;
		width: 340px;
		background: #FFFFFF;
		box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
		position: absolute;
		transform: translateX(50%);
		right: 42px;
		top: 34px;
		.title {
            display: flex
            align-items: center
            justify-content: space-between
			height: 32px;
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 600;
			color: #333333;
			// line-height: 32px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.06);
            padding 0 14px ;
		}
		.tag-box {
			display: flex;
			justify-content: space-between;
			.time {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #999999;
			}
		}
		.msg-list {
			padding: 12px 16px;
			box-sizing: border-box;
			height: 190px;
			overflow-y: auto;
		}
        .item-top{
            display: flex
            justify-content: space-between
            margin-bottom: 12px
        }
        .time{
            color: #999999;
        }
        .tag {
            width: 64px;
            height: 22px;
            box-sizing: border-box;
            padding: 4px 8px;
            font-size 12px
            border-radius: 2px;
            white-space nowrap
            &.tag1 {
                background: rgba(100, 183, 255, 0.2);
                color: #1492ff;
                border: 1px solid #1492ff;
            }
            &.tag2 {
                background: rgba(255, 24, 216, 0.1);
                color: #ff18d8;
                border: 1px solid #ff18d8;
            }
            &.tag3 {
                background: rgba(255, 139, 0, 0.1);
                color: #ff8b00;
                border: 1px solid #ff8b00;
            }
            &.tag4 {
                background: rgba(255, 24, 24, 0.1);
                color: #ff1818;
                border: 1px solid #ff1818;
            }
        }
        .nodata {
            color: #000
            text-align: center
            line-height: 150px
			overflow-y: hidden;
		}
        .more{
            cursor pointer
            & > div{
                height: 39px;
                background: #F6F6F6;
                border-radius: 0 0 2px 2px;
                border-radius: 0 0px 2px 2px
                text-align: center;
                line-height: 39px;
                color: #1492FF;
                &::after{
                    display: inline-block;
                    content: '';
                    top 5px;
                    margin-left: 10px;
                    vertical-align: -1px
                    width: 12px;
                    height: 12px;
                    background: url('~@/assets/img/more.png') no-repeat;
                }
            }
        }
		.item {
			margin-top: 10px;
            cursor: pointer;
		}
		.content {
			display: flex;
			align-items: center;
			border-bottom: 1px solid #f0f0f0;
			padding-bottom: 13px;
			.round {
				width: 6px;
				height: 6px;
				background: #FF4D4F;
				border-radius: 10px;
				border: 1px solid #FFFFFF;
				margin-right: 10px;
			}
			.text {
				width: 292px;
				color: #000;
			}
		}
		.view {
			width: 340px;
			height: 39px;
			background: #F6F6F6;
			border-radius: 0px 0px 2px 2px;
			color: #1492FF;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}
		.postion {
			position: relative;
		}
		.up {
			width: 0;
			height: 0;
			border-width: 0 5px 5px;
			border-style: solid;
			border-color: transparent transparent #fff;
			position: absolute;
			top: -10px;
			transform: translateX(-50%);
			left: 60%;
		}
	}
	.active {
		opacity: 1 !important;
		top: 40px;
		z-index: 99;
	}
}
// .menu-item{
//     display: inline-block;
//     width: 16px;
//     height: 16px;
//     background: url('~@/assets/img/sever-filled.png') no-repeat;
//     background-size: 100% 100%;
// }
// .menu {
//     // /deep/ .is-active{
//     //     background: rgba(20, 146, 255, 0.1);
//     //     font-weight: 500;
//     // }
// }

.wrapper{
    box-sizing: border-box;
    background: #F7F8FA;
    .tabs{
        height: 32px;
    }
    :deep(.el-tabs--card>.el-tabs__header){
        border: none;
    }
    :deep(.el-tabs__item){
        height: 30px;
        line-height: 30px;
        &.is-active{
            background: #1492FF;
            color: #fff;
        }
    }
}
</style>
