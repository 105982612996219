import store from '@/store'
const checkAuth = (el, binding) => {
	const { value } = binding
	const roles = store.state.user.roles
	// console.log('value', value, 'roles', roles)
	if (value && value instanceof Array) {
		if (value.length > 0) {
			const elRoles = value
			const hasAuth = roles.some((role) => {
				// 该用户的所有权限(权限数组)中只要有一个满足对应权限 返回true
				return !elRoles.includes(role)
			})
			// console.log('v-auth', hasAuth)
			if (!hasAuth) {
				// 如果没有该权限,从父节点移除当前元素
				el.parentNode && el.parentNode.removeChild(el)
			}
		} else {
			return true
		}
	} else {
		throw new Error('错误:权限数据类型非数组')
	}
}

export default {
	mounted(el, binding) {
		checkAuth(el, binding)
	},
	updated(el, binding) {
		checkAuth(el, binding)
	},
}
